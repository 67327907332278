import React from 'react';

const Title = ({
  title,
  subTitle
}) => {
  return ( 
    // <div class="section-heading">
        <h2 class="section-title"> { title && title } </h2>
      
   );
}
 
export default Title;